<template>
  <Tile z="1">
    <h2>Game Not Found</h2>
    <p>
        The game you are trying to join does not exist. It either was concluded recently, abandoned by its host, or we lost it in the aether.
    </p>
    <Button
        :callback="goHome">
        Go Home
    </Button>
  </Tile>
</template>
<script>
    import Tile from "@/components/Tile.vue";
    import Button from "@/components/Button.vue";
    export default {
        name: 'Waiting',
        components: {
            Tile,
            Button
        },
        methods: {
            startGame() {
                this.$router.push("/new");
            },
            goHome() {
                this.$router.push("/");
            }
        },
    }
</script>
<style scoped lang="less">

</style>